<template>
  <v-layout row>
    <v-flex class="d-flex justify-center align-center">
      <div class="max-w-60">
        <p class="margin-a-0">{{ rate.rateLabel }}</p>
      </div>
      <div class="max-w-120 padding-l-5">
        <CRInput
          v-if="!rate.toggled"
          :value="rate.marketplace"
          class="max-w-100"
          disabled
          hide-details
          type="number"
        />
        <CRInput
          v-else="rate.toggled"
          :value="rate.custom"
          min="0"
          class="max-w-100"
          hide-details
          type="number"
          @input="updateCustom"
        />
      </div>
      <div class="padding-l-5">
        <v-switch
          :input-value="rate.toggled"
          color="success"
          flat
          inset
          @change="updateToggled"
        />
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex'
import { deepClone } from '@/utils/deepClone'

export default {
  props: {
    rate: {
      type: Array,
      default: () => [],
    },
    vehicleTypeKey: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      setRate: 'selectedOperator/setRate',
    }),
    updateCustom(value) {
      const newRate = deepClone(this.rate)
      newRate.custom = value
      this.setRate({
        vehicleTypeKey: this.vehicleTypeKey,
        rateTypeKey: this.rate.rateTypeKey,
        rate: newRate,
      })
    },
    updateToggled(value) {
      const newRate = deepClone(this.rate)
      newRate.toggled = value
      this.setRate({
        vehicleTypeKey: this.vehicleTypeKey,
        rateTypeKey: this.rate.rateTypeKey,
        rate: newRate,
      })
    },
  },
}
</script>
