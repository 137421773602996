<template>
  <v-layout column>
    <!-- back button -->
    <v-layout v-if="isModeAdd" row>
      <v-flex xs12>
        <v-btn @click="pushBack" flat class="margin-l-0 margin-b-4">
          <v-icon>arrow_back</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout
      v-if="!loading"
      class="padding-a-4 border-radius-5 background-blue-pale"
      column
    >
      <SelectedOperatorMiniDetail :operator="operator" />
      <SelectedOperatorRates v-if="rates.length" :vehicle-type-rates="rates" />
    </v-layout>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex'
import { EventBus } from '@/utils/event-bus'
import { deepClone } from '@/utils/deepClone'
import marketRates from '@/services/marketRates'
import SelectedOperatorMiniDetail from './SelectedOperatorMiniDetail.vue'
import SelectedOperatorRates from './SelectedOperatorRates.vue'

export default {
  components: {
    SelectedOperatorMiniDetail,
    SelectedOperatorRates,
  },
  props: {
    mode: {
      type: String,
      default: 'add',
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    isModeAdd() {
      return this.mode === 'add'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    customerAccountId() {
      return this.$store.getters['customerAccounts/getCustomerAccountId']
    },
    companyId() {
      return this.$store.getters['selectedOperator/getCompanyId']
    },
    operator() {
      return this.$store.getters['selectedOperator/getOperator']
    },
    rates() {
      return this.$store.getters['selectedOperator/getRates']
    },
  },
  watch: {
    companyId: {
      immediate: true,
      handler() {
        this.setSelectedOperatorData()
      },
    },
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
      setSelectedOperatorCompanyId: 'selectedOperator/setCompanyId',
      setOperator: 'selectedOperator/setOperator',
      setRates: 'selectedOperator/setRates',
      clearSelectedOperator: 'selectedOperator/clearSelectedOperator',
    }),
    async setSelectedOperatorData() {
      this.loading = true
      const params = {
        customerAccountId: this.customerAccountId,
        companyId: this.companyId,
      }
      try {
        const res = await marketRates.getSelectedOperatorRatesDetail(params)

        this.setOperator({ operator: res.data.operator })
        this.setRates({ rates: res.data.vehicleTypeRates })
      } catch (e) {
        this.showAlert({
          type: 'error',
          message: 'Unable to retrieve operator data',
        })
        console.error(e)
        this.$emit('back')
      }
      this.loading = false
    },
    pushBack() {
      this.clearSelectedOperator()
    },
  },
}
</script>

<style lang="scss" scoped>
.location-container {
  display: flex;
  align-items: center;
  height: 120px;
  padding-bottom: 5px;
}

.operator-name {
  color: $blue;
  word-wrap: break-word;
}
</style>
