<template>
  <v-layout column>
    <div class="padding-t-5">
      <v-tabs v-model="selectedTab" class="mb-4 mt-2">
        <v-tab
          v-for="vehicleType in vehicleTypeRates"
          :key="`vehicle-type-tab-${vehicleType.vehicleTypeKey.toLowerCase()}`"
          class="mx-2"
        >
          {{ vehicleType.vehicleTypeLabel }}
        </v-tab>
      </v-tabs>
    </div>
    <div class="padding-l-5">
      <SelectedOperatorRate
        v-for="(rate, index) in selectedVehicleTypeRates"
        :rate="selectedVehicleTypeRates[index]"
        :vehicle-type-key="selectedVehicleTypeRate?.vehicleTypeKey"
        :key="`selected-vehicle-rate-${selectedTab}-${rate.rateTypeKey}-${index}`"
        row
      />
    </div>
  </v-layout>
</template>

<script>
import SelectedOperatorRate from './SelectedOperatorRate.vue'

export default {
  components: {
    SelectedOperatorRate,
  },
  props: {
    vehicleTypeRates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedTab: 0,
    }
  },
  computed: {
    selectedVehicleTypeRate() {
      return this.vehicleTypeRates?.[this.selectedTab]
    },
    selectedVehicleTypeRates() {
      return this.vehicleTypeRates?.[this.selectedTab]?.rates || []
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .theme--light.v-tabs__bar {
  background-color: $blue-pale !important;
}
</style>
